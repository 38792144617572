import React from "react"
import { Link } from "gatsby"
import Btn from "../common/button"

// Importing Contentful Components
import LandingWhat from "./landingWhat"
import LandingSayHello from "./landingSayHello"
import LandingWhoWe from "./landingWhoWe"

// Section Component
// TODO: Move to common file after cleanup
const SectCont = (props) => {
  const Class = `sect__cont ` + (props.className ? props.className : '')
  return (
    <section className={Class}>
      {props.children}
    </section>
  )

}

// Section "What we do - Section"
const SectionWhat = (props) => {
  return (
    <SectCont className={props.className}>
      <div className="cont--spaced">
        <LandingWhat />
        <div className="sect__btns">
          {/* <Btn btnTitle="Our Group" className="btn--secn" Link btnLink="/group"/> */}
          <Btn btnTitle="Say Hello!" className="btn--prim" Link btnLink="/contact" />
        </div>
      </div>
    </SectCont>
  )
}

// Section "Explore - Section"
const SectionExplore = (props) => (
  <SectCont className={props.className}>
    <div className="cont--spaced sect__content">
      <LandingSayHello />
{/* 
      <div className="sect__form dn">
        <form action="" className="form__cont">
          <div className="input input--lg input--btn">
            <input type="text" placeholder="Email Address" />
            <a href="" data-hover="Say Hello!" className="input--btn__btn btn btn--prim">Say Hello!</a>
          </div>
          <span className="input__note">We will get in touch with you!</span>
        </form>
      </div> */}

      <div className="sect__btns">
        <Btn btnTitle="Explore Startups Privately" className="btn--secn" Link btnLink="/careers"/>
        {/* <Btn btnTitle="Get in touch!" className="btn--prim" Link btnLink="/contact" /> */}
      </div>
    </div>
  </SectCont>
)

// Section "Who we work with"
const SectionWho = (props) => (
  <SectCont className={props.className}>
    <div className="cont--spaced">
      <LandingWhoWe />
      <div className="sect__btns">
        <Btn btnTitle="Explore Privately" className="btn--secn" Link btnLink="/careers"/>
        <Btn btnTitle="Get in touch!" className="btn--prim" Link btnLink="/contact" />
      </div>
    </div>
  </SectCont>
)

// Landing Content Final Component
const LandingContent = (props) => (
  <div >
    <SectionWhat className="sect--default" />
    <SectionExplore className="sect--light sect--diagonal sect--diagonal--alone" />
    {/* <SectionWho className="sect--default" /> */}
  </div>
)

export default LandingContent
