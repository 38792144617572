import React from "react"
import { Link } from "gatsby"
import Typist from 'react-typist-n10'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/common/hero'
import Btn from '../components/common/button'
import LandingContent from '../components/landing/landingContent'
import LandingNav from '../components/landing/landingNav'


const HeroContent = () => {

  const AnimatedText = [
    'Senior Engineers',
    'Engineering Leaders',
    'Founders',
    'Experienced Operators'
  ]

  return (
  <div className="hero__content">
    <h1 className="title--main">
      We are <br className="show--md"/>
      <span className="title--main--alt">
        <Typist avgTypingDelay={100} startDelay={200} repeat>
          {AnimatedText.map(word => ([
            <span key={AnimatedText.toString()}>{word}</span>,
            <Typist.Backspace count={word.length} delay={1600} />,
          ]))}
        </Typist>
      </span>
    </h1>

        
    <p className="hero__text"></p>
    <p className="hero__text ">
      We are a group of senior engineers, engineering leaders and experienced operators in product, sales and marketing.
      We have created and scaled critical systems, important organizations, and popular open source software. 
      We co-invest alongside top tier VCs and help the founders with our network and expertise.
    </p>

    <div className="hero__btns">
      <Btn btnTitle="Meet the Group" className="btn--secn btn--ir" Link btnLink="/group">
        <span className="i i-arw-r"></span>
      </Btn>
      <Btn btnTitle="Explore Startups" className="btn--secn btn--ir" Link btnLink="/careers">
        <span className="i i-arw-r"></span>
      </Btn>
      <Btn btnTitle="Say hello!" className="btn--prim" Link btnLink="/contact" />
    </div>

    <div className="hero__foot">
      <Btn btnTitle="Learn More" className="btn--text btn--ib" Link btnLink="#more">
        <span className="i i-arw-b"></span>
      </Btn>
    </div>
  </div>
  )
}


// Index Page
const IndexPage = () => (
  <Layout>
    <SEO title="Welcome to PrimeSet" />
    <Hero className="hero--main">
      <div className="cont--spaced">
        <div className="hero__head">
          <div className="hero__logo__cont">
            <a href="" className="hero__logo">PrimeSet</a>
          </div>
          <LandingNav className="" />
        </div>
        <HeroContent />
      </div>
    </Hero>
    <div id="more"></div>
    <LandingContent className="" />
  </Layout>
)

export default IndexPage
