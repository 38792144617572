import { Link } from "gatsby"
import React from "react"
import Nav from "../common/nav"


const NavLanding = ({ className }) => (
  <Nav className="nav--hero" />
)

export default NavLanding



