import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"


export default () => (
  <StaticQuery
    query={graphql`
      query LandingSayHelloQuery {
        allContentfulHomeSayHelloBlock {
          edges {
            node {              
              titleName {
                childMarkdownRemark {
                  html
                }
              }
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <h1 className="sect__title sect__title--md">{ data.allContentfulHomeSayHelloBlock.edges[0].node.title }</h1>
        <h1 className="sect__title sect__title--md" dangerouslySetInnerHTML={{ __html: data.allContentfulHomeSayHelloBlock.edges[0].node.titleName.childMarkdownRemark.html }}></h1>
        <div className="sect__text" dangerouslySetInnerHTML={{ __html: data.allContentfulHomeSayHelloBlock.edges[0].node.description.childMarkdownRemark.html }} />
      </div>
    )}
  />
)

