import { Link } from "gatsby"
import React from "react"

export default (props) => {
  const Class = `btn ` + (props.className ? props.className : '')

  if (props.Link) {
    return (
      <Link to={props.btnLink} className={Class} data-hover={props.btnTitle}>
        <span className="btn__text" data-hover={props.btnTitle}>{props.btnTitle}</span>
        {props.children}
      </Link>
    )
  } else {
    return (
      <a href={props.btnHref} className={Class} data-hover={props.btnTitle}>{props.btnTitle}</a>
    )
  }
}





