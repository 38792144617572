import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"


export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allContentfulWhatWeDo {
          edges {
            node {
              title
              brief {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          totalCount
        }
      }
    `}
    render={data => (
      <div>
        {/* { data.allContentfulWhatWeDo.totalCount }
      
        */}
        <h1 className="sect__title">{ data.allContentfulWhatWeDo.edges[0].node.title }</h1>
        <div className="sect__text" dangerouslySetInnerHTML={{ __html: data.allContentfulWhatWeDo.edges[0].node.brief.childMarkdownRemark.html }} />
      </div>
    )}
  />
)

